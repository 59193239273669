<template>
    <div>
      <vx-card title="">
        <div class="vx-row">
          <div class="vx-col xxl:w-3/4 w-full">
            <h2>Confirmar datos para el retiro recurrente</h2>
          </div>
        </div>
  
        <template>
          <div class="vx-row">
            <div class="vx-col w-full ">
              <vs-alert v-if="onError" color="danger" class="mt-3 mb-3" style="height: auto">{{ errorMssg }}</vs-alert>
            </div>
          </div>

          <div class="vx-row mt-base">
            <div class="vx-col w-full">
              <h4>Cuentas bancarias</h4>
              <p class="mb-2">
                Indica la cuenta de banco a la cual deseas que se realice el retiro recurrente.
              </p>
            </div>
            <div class="vx-col lg:w-2/3 md:w-3/4 sm:w-full w-full">
              <div id="data-list-card-view" class="data-list-container">
                <vs-table v-if="formattedAccounts.length > 0" class="mt-5" ref="cardsTable" v-model="accountRowSelected"
                  @selected="onAccountSelected" :data="formattedAccounts" noDataText="No hay cuentas disponibles">
                  <template slot="thead">
                    <vs-th class="bold">CLABE</vs-th>
                    <vs-th class="bold">Banco</vs-th>
                    <vs-th class="bold">Acciones</vs-th>
                  </template>
  
                  <template slot-scope="{data}">
                    <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td class="calif-container">
                          <p class="font-medium">{{ tr.clabe }}</p>
                        </vs-td>
                        <vs-td class="calif-container">
                          <span class="flex items-center">
                            <p class="font-medium">{{ tr.bank_name }}</p>
                          </span>
                        </vs-td>
                        <vs-td>
                          <span v-if="!isAccountSelected(tr)" class="text-sm text-primary">Seleccionar</span>
                          <span v-if="isAccountSelected(tr)" class="flex items-center">
                            <feather-icon icon="CheckIcon" svgClasses="h-6 w-6 text-success" class="mr-2" />
                            <span class="text-sm text-success bold">Elegida</span>
                          </span>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
                <div style="border-left: solid #CBFF00;" v-else>
                  <div class="flex justify-left items-center mt-3 ml-3">
                    <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-danger" />
                    <b class="ml-2">Aún no tienes una cuenta registrada</b>
                  </div>
                  <p class="mt-2 ml-3">
                    Envía por lo menos $1 MXN para dar de alta tu cuenta bancaria. Importante: Es necesario que el banco se
                    encuentre a tu nombre,
                    de lo contrario no podremos realizar el retiro recurrente.
                    <br>
                    <br>
                    Banco: STP (Sistema de Transferencias y Pagos)<br>
                    CLABE: {{ stpClabe }}<br>
                    Beneficiario: Aquí pones tu nombre<br>
                    Método de transferencia: SPEI
                  </p>
                </div>
              </div>
            </div>
          </div>
          <vs-divider color="success" class="bold mt-base"></vs-divider>
          <div class="vx-row mt-5 mb-base">
            <div class="vx-col w-full">
              <h4>Día de retiro</h4>
              <p class="mb-2">
                Indica el día en el que te gustaría retirar automáticamente tu ingreso pasivo.
                En caso de que el día sea inhábil, se procesará al siguiente día hábil.
              </p>
            </div>
            <div class="vx-col w-full md:w-1/3 lg:w-1/4">
              <div class="vx-row mt-4">
                <div class="vx-col w-full ">
                  <p class="bold">Día del mes para el retiro</p>
                </div>
              </div>
              <div class="vx-row mt-2">
                <div class="vx-col w-full">
                  <v-select name="processingDay" class="vs-custom w-full" v-model.lazy="processingDay"
                    v-validate="'required'" :options="daysCollection" :reduce="item => item.value" :clearable="false"
                    :searchable="false" placeholder="Selecciona">
                    <div slot="no-options">No hay coincidencias</div>
                  </v-select>
                </div>
              </div>
            </div>
          </div>  
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex">
                <vs-button class="mt-2" type="border" @click="cancel">Atrás</vs-button>
                <vs-button class="ml-6 mt-2" @click="saveSettingsRequest">Guardar y continuar</vs-button>
              </div>
            </div>
          </div>
        </template>
      </vx-card>

      <!-- DISCLAIMER INVERSION AUTOMATICA -->
      <automatic-investment-disclaimer/>

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
    </div>
  </template>
  
  <script>
  import currencyHelper from "@mixins/currencyHelper";
  import stpHelper from "@mixins/stpHelper";
  import AutomaticInvestmentDisclaimer from "@components/investor/widgets/AutomaticInvestmentDisclaimer";
  import {BankAccountStatus} from "@/bl/BankAccountStatus";
  
  export default {
    name: "MonthlyRecurrentWithdrawal",
    mixins: [currencyHelper, stpHelper],
    props: {
      investorPlan: { type: Object, required: true },
    },
    components: {
      AutomaticInvestmentDisclaimer
    },
    data() {
      return {
        processingDay: this.investorPlan.recurrent_withdrawal_day==0? 1:this.investorPlan.recurrent_withdrawal_day,
        daysCollection: [],
        retryCollection: [],
        userAccounts: [],
        formattedAccounts: [],
        accountRowSelected: null,
        accountIdSelected: null,
        onError: false,
        errorMssg: null,
        mexicoCountryId: 700,
        stpClabe: null
      }
    },
    async beforeMount() {
      this.showLoading(true);
      this.setCollections();
      await this.getUserAccounts();
      if (this.formattedAccounts.length == 0) {
        this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
      }
      this.accountIdSelected = this.investorPlan.recurrent_withdrawal_bank_account_id;
      this.showLoading(false);
      this.$refs.rgGeolocator.askForGeolocation();
    },
    computed: {
      selectedAccount() {
        return this.formattedAccounts.find((f) => f.id == this.accountIdSelected);
      },
    },
    methods: {
      async saveSettingsRequest() {
        // se pide la geolocalizacion para la operacion
        this.clean();
        let valid = this.runValidations();
        if (!valid) {
          this.onError = true;
          this.missingFieldsNotif();
          return;
        }
        /* TODO: 2fa deshabilitado temporalmente
        await this.$emit('on-save-prefs-auth-request');*/
        await this.doSaveSettings();
      },
      async doSaveSettings() {
        this.showLoading(true);
        try {
          let payload = {
            bank_account_id: this.accountIdSelected,
            processing_day: this.processingDay,
            geolocation: this.CurrentGeolocationData
          };
          this.injectAccountMetadataToPayload(payload);
          await axios.post(`api/v2/investor/${this.InvestorId}/setRecurrentWithdrawal`, payload);
          this.successNotifDialog('Operación exitosa', 'Se han actualizado exitosamente tus preferencias de retiro recurrente.');
          await this.$router.replace({ name: 'planesInversion' });
          //await this.$emit('on-saved');
        }
        catch (e) {
          this.errorNotifDialog('Error en guardado', 'No ha sido posible actualizar la información, intente más tarde.');
        }
        this.showLoading(false)
      },
      runValidations() {
        if (this.accountIdSelected === null) {
          this.errorMssg = "Debes seleccionar una cuenta.";
          return false;
        }
        if (this.processingDay === null || this.processingDay == 0) {
          this.errorMssg = "Debes seleccionar un día para el retiro.";
          return false;
        }
        return true;
      },
      async cancel() {
        await this.$emit('on-cancel');
      },
      setCollections() {
        let c = [];
        for (let i = 1; i <= 28; i++) {
          c.push({ value: i, label: i });
        }
        this.daysCollection = c;
  
        let r = [];
        for (let i = 1; i <= 4; i++) {
          r.push({ value: i, label: i });
        }
        this.retryCollection = r;
      },
      async getUserAccounts() {
        try {
          const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
          const accs = res.data;
          this.userAccounts = accs;
          this.formattedAccounts = [];
          accs.forEach(f => {
            let nacc = this.getFormattedAccount(f);
            if (nacc.is_verified) {
              this.formattedAccounts.push(nacc);
            }
          });
  
          this.formattedAccounts.sort(function (a, b) {
            if (a.is_default) {
              return -1
            }
            else if (b.is_default) {
              return 1;
            }
            else if (a.is_verified && b.is_verified) {
              return a.clabe > b.clabe;
            }
            else if (a.is_verified && !b.is_verified) {
              return -1
            }
            else if (!a.is_verified && b.is_verified) {
              return 1
            }
            return 0;
          });
        }
        catch (e) {
        }
      },
      getFormattedAccount(f) {
        let nacc = {
          id: f.id,
          bank_id: f.bank_id,
          is_default: f.is_default === 1,
          is_verified: f.verified === BankAccountStatus.VERIFIED,
          status_name: BankAccountStatus.getAccountStatusName(f.verified),
        };
  
        if (f.bank && f.bank.country_id === this.mexicoCountryId) {
          nacc.bank_name = f.bank.short_name;
          nacc.clabe = f.clabe;
        } else {
          nacc.bank_name = f.bank.name;
          nacc.clabe = f.bic_swift;
        }
        return nacc;
      },
      onAccountSelected(tr) {
        if (tr.id === this.accountIdSelected) {
          return;
        }
        else {
          this.accountIdSelected = tr.id
        }
      },
      isAccountSelected(tr) {
        return tr.id === this.accountIdSelected;
      },
      clean() {
        this.onError = false
        this.errorMssg = null
      },
    },
  }
  </script>
  
  
  <style lang="scss">
  #data-list-card-view {
    .vs-con-table {
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
  
        >span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search {
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
  
          td {
            padding: 10px;
  
            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
  
            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
  
            &.img-container {
  
              span {
                display: flex;
                justify-content: flex-start;
              }
            }
          }
  
          td.td-check {
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }
  
        th.td-check {
          padding: 0 15px !important;
        }
  
        tr {
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  