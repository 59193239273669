<template>
    <div>
      <big-title>
        Activar Ingreso <span>Pasivo</span>.
      </big-title>
  
      <!-- retiro recurrente -->
      <monthly-recurrent-withdrawal
        v-if="isMounted"
        ref="monthlyRecurrentWithdrawal"
        :investor-plan="investorPlan"
        @on-saved="onMonthlyInvestSaved"
        @on-save-prefs-auth-request="requestAuthSaveMonthlyPrefs"
        @on-cancel="onCancel"
      />
      <!-- Termina retiro recurrente -->
  
      <vs-popup :active.sync="isCancelWarningPopupActive" class="header-primary" title="Aviso Importante">
        <div class="pt-1 p-4">
          <h4 class="mb-2">¡Atención!</h4>
          <p>
            Debido a que deseas cancelar el proceso de configuración, y aún existen campos pendientes por completar,
            el retiro recurrente se <span class="bold">desactivará</span>.
          </p>
          <p class="mt-2">
            Si deseas mantenerlo activo, completa los campos correspondientes y finaliza el proceso de configuración dando
            clic en "Continuar".
          </p>
          <div class="flex mt-2">
            <vs-button class="mt-4" @click="doContinue">Continuar</vs-button>
            <vs-button type="border" class="ml-6 mt-4" @click="doCancel">Desactivar</vs-button>
          </div>
        </div>
      </vs-popup>
  
      <!-- Componente para segundo factor -->
      <two-factor-auth
        :user-id="UserId"
        custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para ejecutar la operación."
        @on-code-validated="onAuth" />
    </div>
  </template>
  
  <script>

  import MonthlyRecurrentWithdrawal from "@components/investor/plans/MonthlyRecurrentWithdrawal";
  import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
  import auth2faHelper from "@mixins/auth2faHelper";
  export default {
    name: "RecurrentWithdrawal",
    components: {
      MonthlyRecurrentWithdrawal,
      TwoFactorAuth
    },
    mixins: [auth2faHelper],
    data() {
      return {
        investorPlan: {
          id: null,
          card_id: null,
          card_amount: null,
          reinvestment_percentage: null,
          qualifications: null,
          monthly_day: null,
          available_money: null,
          retry: null,
          min_autoinvest_project_amount_mxn: null,
          max_autoinvest_project_amount_mxn: null,
          autoinvest_applicant_payments: null,
          autoinvest_deposits: null,
        },
  
        factorEnabled: null,
        planWasActivated: false,
        cancelWarningPopupActive: false,
        isMounted: false,
      }
    },
    async mounted(){
      this.showLoading(true);
      this.isMounted = false;
      await this.getPlansInfo();
      this.factorEnabled = await this.getUser2FAStatus(this.UserId);
      this.isMounted = true;
      this.showLoading(false);
    },
    computed: {
      isCancelWarningPopupActive: {
        get() {
          return this.cancelWarningPopupActive;
        },
        set(val) {
          this.cancelWarningPopupActive = val;
        }
      },
    },
    methods: {
      async getPlansInfo(){
        try {
          const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
          this.investorPlan = res.data.plan;
          this.planWasActivated = (this.investorPlan.recurrent_withdrawal_day != 0 || this.investorPlan.recurrent_withdrawal_bank_account_id);
        }
        catch (e) {
        }
      },
      async requestAuthSaveMonthlyPrefs(){
        /* TODO: 2fa desactivado temporalmente
        if(!this.checkForFactorEnabled()){
          return;
        }
        this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true); */
      },
      async onAuth(code){
        // await this.$refs.monthlyAutoInvest.doSaveSettings(code);
        await this.$refs.MonthlyAutoInvestDirectBilling.doSaveSettings(code);
      },
      async onMonthlyInvestSaved(){
        let mssg = "El Abono Recurrente se activó exitosamente, pero será importante que completes " +
          "la sección de Criterios de inversión para que tu forma de riego sea efectiva. La Inversión Automática también fue habilitada.";
        // mssg += "\n\nEncuentra este apartado en la parte inferior de esta sección de Mi forma de riego que " +
        //   "también impactará en la inversión automática en caso de que la tengas activa.";
        this.successNotifDialog("¡Atención!", mssg);
        await this.goToInvestmentPrefs();
      },
  
      async doCancel(){
        this.isCancelWarningPopupActive = false;
        await this.backToMain();
      },
      async doContinue(){
        this.isCancelWarningPopupActive = false;
      },
  
      async onCancel(){
        // verificar si los datos se guardaron o no, para mostrar el mensaje de warning
        // previo a cancelar
        if(!this.planWasActivated){
          this.isCancelWarningPopupActive = true;
        }
        else {
          // si lo datos se guardaron previamente, se retorna a la vista principal
          // sin mostrar el warning
          await this.backToMain();
        }
      },
      async goToInvestmentPrefs(){
        await this.$router.replace({name: 'criteriosInversion'});
      },
      async backToMain(){
        await this.$router.replace({name: 'planesInversion'});
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>